<template>
    <div class="small-nav">

        <!-- Nav bar items -->
        <div class="small-nav-bar small-nav-push">
            <div class="container">

                <button v-if="fullHeader" class="button--unstyled small-nav-trigger" @click="togglePanel">
                    <span class="icon icon-menu" role="button" :aria-label="navAriaLables.showNavigation"></span>
                </button>

                <a :href="addDomainToPath(nav.commoncontent.commonContent.advisorshome.url)" class="small-nav-logo">
                    <img src="../assets/vanguard.svg" alt="Vanguard Home">
                </a>

                <button
                        v-if="fullHeader"
                        :title="!searchOpen ? 'Search' : 'Close'"
                        ref="searchToggle"
                        @click="toggleSearch"
                        @keydown.space="toggleSearch"
                        class="button--unstyled small-nav-search"
                        type="button">
                    <span v-if="!searchOpen" class="icon icon-search" role="button" :aria-label="navAriaLables.searchVanguard"></span>
                    <span v-else class="icon icon-close" role="button" :aria-label="navAriaLables.closeSearchPanel"></span>
                </button>

            </div>
        </div>

        <div v-show="panelOpen" class="small-nav-panel small-nav-push" ref="panel">

            <!-- Nav panel header w/logo & close button -->
            <div class="panel-header">
                <a :href="addDomainToPath(nav.commoncontent.commonContent.advisorshome.url)" class="small-nav-logo panel-logo">
                    <img src="../assets/vanguard.svg" alt="Vanguard Home">
                </a>
                <button class="button--unstyled small-nav-close" type="button" ref="closeButton" @click="hidePanel">
                    <span class="icon icon-close" role="button" :aria-label="navAriaLables.closeSearchPanel"></span>
                </button>
            </div>

            <!-- Level one nav -->
            <div class="small-nav--one" id="nav-one">

                <div class="small-nav-one--links" ref="nav1">
                    <ul class="small-nav-items" @click="showSubPanel">
                        <template v-for="(link, index) in silosContent.silos">
                            <template v-if="link.displayName !== 'eConnect'">
                                <li class="small-nav-item" :key="index" >
                                    <template v-if="link.columns">
                                        <a :data-subpanel="'nav-two'"
                                           :data-subnav="index"
                                           href="javascript:void(0)"
                                           :class="link.classes"
                                           v-html="link.displayName"
                                           aria-label="Navigation">
                                        </a>
                                        <span class="icon icon-right fg--red" aria-hidden="true"></span>
                                    </template>
                                    <template v-else>
                                        <a :href="isLoggedOn ? addDomainToPath(link.secureLink) : addDomainToPath(link.unSecureLink)"
                                           :class="link.classes"
                                           v-html="link.displayName"
                                           @click="sendLaunchData">
                                        </a>
                                    </template>
                                </li>
                            </template>
                        </template>
                    </ul>
                </div>

                <!-- Level two nav -->
                <div class="small-nav-subpanel small-nav--two" ref="subpanel" id="nav-two">

                    <!-- Back to level one -->
                    <a href="#" class="subpanel-header fg--white bg--almost-black --bold"
                       @click="hideSubPanel"
                       data-subpanel="nav-two">
                        <span class="icon icon-left" :aria-label="navAriaLables.goBack"></span>
                        {{ navTwoLabel }}
                    </a>
                    <!-- Level two links -->
                    <ul v-if="navTwoLabel !== 'eConnect'" class="small-nav-items" @click="showSubPanel">
                        <template v-for="(link, index) in navTwo">
                            <li class="small-nav-item" v-for="(section, sectionIndex) in link" :key="'navItem'+index+sectionIndex">
                                <a :data-subpanel="section.menuItems ? 'nav-three' : null"
                                   :data-subnav="section.menuItems ? index : null"
                                   :data-subSection="sectionIndex"
                                   :href="link.url ? addDomainToPath(link.url) : '#'"
                                   :class="link.classes"
                                   v-html="section.heading">
                                </a>
                                <span v-if="section.menuItems" class="icon icon-right fg--red" aria-hidden="true"></span>
                            </li>

                        </template>
                    </ul>
                </div>

                <!-- Level three nav -->
                <div class="small-nav-subpanel small-nav--three" ref="subsubpanel" id="nav-three">

                    <!-- Back to level two -->
                    <a href="#" class="subpanel-header fg--white bg--almost-black --bold"
                       @click="hideSubPanel"
                       data-subpanel="nav-three">
                        <span class="icon icon-left" aria-label="Go back:"></span>
                        {{ navThreeLabel }}
                    </a>

                    <!-- Level three links -->
                    <ul class="small-nav-items" v-if="navThree" @click="sendLaunchData">
                        <template v-if="navThree.actionLink.actionlink.displayName" >
                            <li class="small-nav-item" >
                                <CTA :url="isLoggedOn ? navThree.actionLink.actionlink.secureLink : navThree.actionLink.actionlink.unSecureLink"
                                     :text="navThree.actionLink.actionlink.displayName" :chevron="'right'" :targetBlank="false" />
                            </li>
                        </template>
                        <template v-for="(link, index) in navThree.menuItems" >
                            <li class="small-nav-item" v-if="link.displayName !=='Product screener' && link.displayName !=='Factor investing tool'" :key="index" >
                                <a :href="isLoggedOn ? addDomainToPath(link.secureLink) : addDomainToPath(link.unSecureLink)" :class="link.classes"
                                   v-html="link.displayName">
                                </a>
                            </li>
                            <li v-else v-show="isInternalSite" class="small-nav-item" :key="index" >
                                <a v-if="isInternalSite" :href="isLoggedOn ? addDomainToPath(link.secureLink) : addDomainToPath(link.unSecureLink)" :class="link.classes"
                                   v-html="link.displayName">
                                </a>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>

            <!-- These links are part of the global toolbar in the large view. -->
            <ul class="toolbar-dropdown-links">
                <li class="toolbar-dropdown-link">
                    <a :href="addDomainToPath('/tax-center')">Tax Center</a>
                </li>
                <li class="toolbar-dropdown-link">
                    <a :href="addDomainToPath(headerUtilityBar.subscribe.subscribe.link)" v-html="headerUtilityBar.subscribe.subscribe.label"></a>
                </li>
                <template v-if="!isLoggedOn">
                    <li class="toolbar-dropdown-link" id="signInLink">
                        <a href="#" v-html="headerUtilityBar.signIn.signin.unsecureLabel" @click="onSigninClick" ></a>
                    </li>
                    <li class="toolbar-dropdown-link">
                        <a :href="addDomainToPath(headerUtilityBar.contactUs.contactus.link)" v-html="headerUtilityBar.contactUs.contactus.label"></a>
                    </li>
                    <li v-if="!checkRegisterPage() && !checkInternal()" class="toolbar-dropdown-link">
                        <a :href="headerUtilityBar.register.register.unsecureLink" v-html="headerUtilityBar.register.register.unsecureLabel"></a>
                    </li>
                </template>
                <template v-else>
                    <li class="toolbar-dropdown-link">
                        <a v-if="!checkInternal()" :href="addDomainToPath(headerUtilityBar.myProfile.myprofile.items[0].secureLink)" v-html="headerUtilityBar.myProfile.myprofile.secureLabel"></a>
                    </li>
                    <li class="toolbar-dropdown-link">
                        <a :href="addDomainToPath(headerUtilityBar.contactUs.contactus.link)" v-html="headerUtilityBar.contactUs.contactus.label"></a>
                    </li>
                    <li v-if="checkInternal()" class="toolbar-dropdown-link" id="signInLink">
                        <a href="#" v-html="headerUtilityBar.signOut.signout.secureLabel" @click="onInternalSignOutClick"></a>
                    </li>
                    <li v-else class="toolbar-dropdown-link" id="signInLink">
                        <a href="#" v-html="headerUtilityBar.signOut.signout.secureLabel" @click="onAuth0LinkClick" ></a>
                    </li>
                </template>
            </ul>
            <ul class="toolbar-dropdown-links" v-for="(toolBarLink, index) in toolBarLinks.slice(1)" :key="'links'+index">
                <li class="toolbar-dropdown-link" v-for="(toolBarDropDown, linkIndex) in toolBarLink" :key="'link'+index+linkIndex">
                    <a :href="toolBarDropDown.urlSuffix" v-html="toolBarDropDown.siteDisplayName"
                       v-on="index === toolBarLinks.length - 2 && linkIndex === toolBarLink.length - 1 ? { keydown: onTabForward } : {}"></a>
                </li>
            </ul>
        </div>

        <!--  Search dropdown panel -->
        <transition name="slide-toggle">
            <SearchPanel
                    v-if="!toggleCoveoSearch && searchOpen"
                    :class="isSearchPage ? ['search-panel-small--search','search-panel-small'] : 'search-panel-small'"
                    :closePanel="closeSearch"
                    :content="nav"
                    :isInternalSite="isInternalSite"
                    :isLoggedOn="isLoggedOn"
                    v-on="$listeners"
                    :isSearchPage="isSearchPage"/>
            <div v-if="scriptsLoaded || isSearchPage">
                <slot v-bind:searchOpen="searchOpen"></slot>
            </div>
        </transition>

        <!-- Page-blocking overlay for side nav -->
        <div v-if="!searchOpen" class="small-nav-overlay small-nav-push" @click="hidePanel"></div>

        <!-- Page-blocking overlay for search panel  -->
        <div v-if="!isSearchPage" class="nav-overlay"  :class="searchOpen?'nav-overlay-on-searchOpen':''" @click="closeSearch"></div>
    </div>
</template>

<script>
    import { eStudioHelper } from '../js/mixins';
    import SearchPanel from './GlobalNav/SearchPanel.vue';
    import CTA from '@vg-faset/ui/src/components/cta/CTA';
    import logger from "@/js/services/logger.service";
    import { EventBus } from '@vg-faset/core';
    import { adobeLaunch } from 'vg-fas-adobe-launch';
    import envConfigService from "../js/services/envConfig.service";
    import axios from "axios";
    import ENDPOINTS from "../../config/endpoints.constant";

    export default {
        name: 'GlobalNavSmall',
        mixins: [ eStudioHelper ],
        components: { SearchPanel, CTA },
        props: {
            globalNAVData: {
                type: Object
            },
            isLoggedOn: {
                type: Boolean
            },
            fullHeader: {
                type: Boolean,
                default: true
            },
            isSearchPage: {
                type: Boolean,
                default: false
            },
            toggleCoveoSearch: {
                type: Boolean,
                default: true
            },
            scriptsLoaded: {
                type: Boolean,
                default: false
            },
            afterCoveoInit: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                panelOpen: false,
                page: null,
                nav: this.globalNAVData,
                sectionContent: this.globalNAVData.silossectionscontent,
                silosContent: this.globalNAVData.siloscontent,
                headerUtilityBar: this.globalNAVData.headerutilitybar.globalHeaderUtilityBar.globalheaderutilitybar,
                navAriaLables: this.globalNAVData.commoncontent.commonContent.arialablesforaccessibility,
                navTwo: null, // Currently active second-level nav
                navTwoLabel: '',
                navThree: null, // Currently active third-level nav
                navThreeLabel: '',
                searchOpen: false,
                toolBarLinks: [],
                isInternalSite: window.location.host.indexOf("fasi.vanguard.com") > -1
            };
        },
        methods: {
            togglePanel() {
                if (this.panelOpen) {
                    this.hidePanel();
                } else {
                    this.showPanel();
                }
                EventBus.$emit("open-coveo-search",this.searchOpen);
            },
            async onSigninClick(e) {
                let target = window.location.pathname;
                if (e) {
                    e.preventDefault();
                }
                if (this.isInternalSite) {
                    let secureURL = "/flk-fas-globalnavigation-webapp/protected/login/redir?url=";
                    adobeLaunch.trackSignInClick();
                    return window.location.assign(secureURL + target);
                }
                else {
                    await this.onAuth0LinkClick();
                }
            },
            clearVGSession() {
                axios.get(
                    envConfigService.buildWebappRegionURL() + ENDPOINTS.CLEAR_VG_SESSION_URL
                );
            },
            onInternalSignOutClick() {
                this.clearVGSession();
                window.location.assign("/");
            },
            async onAuth0LinkClick(e) {
                this.clearVGSession();
                console.log("clearVGSession called in onAuth0LinkClick");
                let url;
                const env = await envConfigService.get();
                const signInUrl = env.auth0SignInURL;
                const signOutUrl = env.auth0SignOutURL;
                let target;
                if(window.location.hash) {
                    target = `${window.location.pathname}${window.location.search}` + "&urlhash=" + `${window.location.hash}`.substring(1);
                } else{
                    target = `${window.location.pathname}${window.location.search}`;
                }
                adobeLaunch.trackSignInClick();
                if (e) {
                    e.preventDefault();
                }
              /*if user clicked on sign in from register page, then redirect to home page*/
              if (target.endsWith('/register')) {
                target = '';
              }
                url = this.isLoggedOn ? signOutUrl : signInUrl + target;
                console.log("signInUrl ", signInUrl, " signOutUrl ", "target ", target, signOutUrl, " url ", url);
                return window.location.assign(url);
            },
            generaToolbarLinks(){
                let innerDataList = [];
               let sitePreferences = Object.values(this.headerUtilityBar.sitePreferences)
                for(var i = 0; i < sitePreferences.length; i++) {
                    if (sitePreferences[i].dividerNext === 'true') {
                        innerDataList.push(sitePreferences[i]);
                        this.toolBarLinks.push(innerDataList);
                        innerDataList = [];
                    } else {
                        innerDataList.push(sitePreferences[i]);
                    }
                    //for last group
                    if (i === sitePreferences.length - 1 && innerDataList.length > 0) {
                        this.toolBarLinks.push(innerDataList);
                    }
                }
            },
            showPanel() {
                // This and hidePanel deal with the base panel in which the nav appears
                this.resetSubnavs();
                this.closeSearch();
                document.body.classList.add('--small-nav-open');
                window.addEventListener('keydown', this.escKeyListener);

                this.panelOpen = true;
                this.$refs.closeButton.focus();
            },

            hidePanel() {
                document.body.classList.remove('--small-nav-open');
                window.removeEventListener('keydown', this.escKeyListener);
                this.panelOpen = false;
                // To open the search bar on search results page
                if (this.isSearchPage) {
                    this.openSearch();
                }
            },

            showSubPanel(e) {
                // it knows which panel to slide in based on the 'data-subpanel' attribute.
                let target = e.target.getAttribute('data-subpanel');
                let targetSubnav = e.target.getAttribute('data-subnav');
                let targetPanel = document.getElementById(target);

                // Some links don't have a submenu
                if (!target || !targetSubnav) {
                    return;
                }
                // This method works for both subnav levels (2 and 3);
                e.preventDefault();

                // Populate the next level of nav links using the contents
                // of the object referenced in 'data-subnav'
                if (target === 'nav-two') {
                    const silosArray = this.silosContent.silos[targetSubnav].columns;
                    const vm = this;
                    this.navTwo =[];
                    silosArray.forEach(silo => {
                        vm.navTwo.push(vm.nav.silossectionscontent[silo.sectionsName]);
                    });
                    this.navTwoLabel = e.target.textContent;
                }

                else {
                    let targetSection = e.target.getAttribute('data-subSection');
                    this.navThree = this.navTwo[targetSubnav][targetSection];
                    this.navThreeLabel = e.target.textContent;
                }
                // Slide in the panel
                targetPanel.classList.add('--active');
            },

            hideSubPanel(e) {
                e.preventDefault();
                let target = e.target.getAttribute('data-subpanel');
                let targetPanel = document.getElementById(target);
                targetPanel.classList.remove('--active');
            },

            // When we close the nav, reset it to its top level
            resetSubnavs() {
                this.$refs.subpanel.classList.remove('--active');
                this.$refs.subsubpanel.classList.remove('--active');
            },

            // Accessibility/keyboard helpers
            onTabForward(e) {
                if (e.key === 'Tab' && !e.shiftKey) {
                    e.preventDefault();
                    this.$refs.closeButton.focus();
                }
            },
            escKeyListener(e) {
                if (e.key === 'Esc' || e.key === 'Escape') {
                    this.hidePanel();
                }
            },

            // search panel releated methods
            closeSearch() {
                document.body.classList.remove('--small-search-open');
                this.searchOpen = false;
                EventBus.$emit("open-coveo-search",this.searchOpen);
            },

            openSearch() {
                if (this.isSearchPage && !this.searchOpen) {
                    this.searchOpen = !this.searchOpen;
                    if (this.searchOpen) {
                        document.body.classList.add('--small-search-open');
                        document.body.style.overflow = 'auto';
                        setTimeout(()=>{
                            EventBus.$emit("open-search");
                        });
                    }
                }
                EventBus.$emit("open-coveo-search",this.searchOpen);
            },

            toggleSearch(e) {
                if (!this.afterCoveoInit) {
                    logger.log("globalnavigation-webapp-coveo: Error getting Coveo scripts")
                    const eventName = "coveoSearchError"
                    const eventDataName = "error"
                    const eventData = { "errorMessage": "Global Nav Coveo Script Error"}
                    adobeLaunch.trackEvent(eventName, eventDataName, eventData)
                    return;
                }

                e.preventDefault();
                this.searchOpen = !this.searchOpen;
                EventBus.$emit("open-coveo-search",this.searchOpen);
                if (this.searchOpen) {
                    EventBus.$emit("load-coveo-suggestions");
                    document.body.classList.add('--small-search-open');
                    //Set coveo focus on searchbox
                    let inputBox = document.querySelector(".magic-box-input input");
                    inputBox.value = "";
                    setTimeout(()=>{
                        inputBox.focus();
                    }, 50);
                    if (this.isSearchPage) {
                        document.body.style.overflow = 'auto';
                        document.querySelector('header').style.position='unset';
                        setTimeout(()=>{
                            EventBus.$emit("open-search");
                        });
                    }else{
                        document.querySelector('.nav-overlay').style.height= window.document.documentElement.offsetHeight +'px';
                    }
                } else {
                    document.body.classList.remove('--small-search-open');
                    document.querySelector('header').style.position='absolute';
                    this.searchOpen = false;
                    EventBus.$emit("open-coveo-search",this.searchOpen);
                    if (this.isSearchPage) {
                        setTimeout(()=>{
                            EventBus.$emit("close-search");
                        });
                    }
                }
            },

            sendLaunchData(e){
                if(e.target.tagName === "A" || e.target.parentNode.tagName === "A" ){
                    let navMenuItem = this.navTwoLabel !== '' ? this.navTwoLabel : e.target.textContent;
                    adobeLaunch.trackNavigationClick({
                        "navigationMenuItem": navMenuItem,
                        "navigationLinkClicked": e.target.textContent
                    });
                }
            },
            checkRegisterPage(){
                return window.location.pathname.split('/').includes('register')
            },
            checkInternal(){
                const curLoc=window.location.host;
                return curLoc.indexOf("fasi.vanguard.com")>-1;
            }
        },
        mounted() {
            this.generaToolbarLinks();
            this.page = document.querySelector('.page');

            if (this.page) {
                this.page.classList.add('small-nav-push');
            }
            if(this.isSearchPage || this.searchOpen){
                document.querySelector('header').style.position='unset';
            }
            this.openSearch();

            document.body.classList.add('--small-nav');

            // Clear out any remnants of the large nav
            document.body.classList.remove('--nav-open');

            let searchFocus = document.querySelector('.small-nav-search');
            if(searchFocus) {
                searchFocus.addEventListener('keydown', () => {
                    document.getElementById('fas-global-search').focus();
                })
            }
        },

        beforeDestroy() {
            this.hidePanel();
            this.closeSearch();
            document.body.classList.remove('--small-nav');
        }
    };
</script>

<style lang="scss">
    $small-panel-width: 85vw;

    // Prevent body scrolling when the nav is active
    .--small-nav-open,
    .--small-search-open {
        height: 100vh;
        overflow: hidden;
        width: 100vw;
    }

    // Some links are normally in the toolbar but migrate here on small
    .--small-nav {

        .global-header {

            .small-nav {

                .toolbar-dropdown-links {

                    li {
                        list-style: none;
                        margin: 0;
                    }

                    li:last-child {
                        border-bottom: 2px solid $color--lighter-gray;
                        padding-bottom: 15px;
                    }

                    &:last-child li:last-child {
                        border-bottom: 0;
                    }
                }

                .toolbar-dropdown-link {
                    a {
                        color: inherit;
                        padding: 15px 0;
                    }
                }
            }
        }
    }

    .global-header {

        .small-nav {
            border: 2px solid $color--lighter-gray;
            border-width: 2px 0;

            .small-nav {

                // The menu button
                &-trigger {
                    border-right: 2px solid $color--lighter-gray;
                    font-size: 20px;
                    height: 100%;
                    margin-left: -18px;
                    padding: 14px 16px 12px 12px;
                    width: 80px;
                }

                &-search {
                    border-left: 2px solid $color--lighter-gray;
                    font-size: 20px;
                    height: 100%;
                    margin-right: -22px;
                    padding: 6px 18px 0;
                    width: 70px;
                    cursor: pointer;

                    .--small-search-open & {
                        padding: 14px 18px 0;
                    }

                    .icon-close {
                        border-bottom: 4px solid $color--red;
                        color: $color--red;
                        display: block;
                        height: calc(50% + 20px);
                        width: 24px;
                    }
                }

                &-logo {
                    flex: 1;
                    padding: 16px 28px 10px;
                    text-align: center;

                    img {
                        display: block;
                        margin: auto;
                        max-width: 130px;
                    }

                    &.panel-logo {
                        text-align: left;

                        img {
                            margin: 0;
                        }
                    }
                }

                &-close.button--unstyled {
                    color: $color--red;
                    font-size: 14px;
                    padding: 8px 18px 0;
                }

                &-panel {
                    background: $color--white;
                    height: 100vh;
                    left: (-1 * $small-panel-width);
                    overflow-x: hidden;
                    overflow-y: scroll;
                    position: fixed;
                    top: 0;
                    width: $small-panel-width;
                    z-index: 10;

                    // Holds the logo and X button
                    .panel-header {
                        display: flex;
                    }
                }

                // Styles for all the nav and subnav links
                &-items {
                    list-style: none;
                    margin: 0;
                    padding: 0 28px;
                }

                &-item {
                    border-bottom: 2px solid $color--lighter-gray;
                    font-size: 15px;
                    margin: 0;
                    padding: 0;
                    position: relative;

                    &:last-child {
                        border-bottom: 0;
                    }

                    a {
                        color: $color--almost-black;
                        padding: 20px 0 18px;

                        &.cta {
                            color: $color--red;
                        }
                    }

                    .icon-right {
                        font-size: 15px;
                        pointer-events: none;
                        position: absolute;
                        right: -8px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                // First level nav
                &--one {
                    top: 54px; // height of the logo
                    width: 100%;
                }

                &-one--links {
                    background: $color--bg-gray;
                    border: 2px solid $color--lighter-gray;
                    border-width: 2px 0;

                    a {
                        font-weight: $font-weight--bold;
                    }
                }

                // Second level nav
                &-subpanel {
                    background: $color--white;
                    left: 1px; // prevents it from showing prematurely
                    min-height: 100%;
                    position: absolute;
                    top: 55px; // height of Vanguard logo bar
                    transform: translateX(100%);
                    transition: 0.25s ease-out transform, 0s visibility 0.25s;
                    visibility: hidden;
                    width: 100%;
                    z-index: 2;

                    &.--active {
                        transform: translateX(0%);
                        transition: 0.25s ease-out transform, 0s visibility;
                        visibility: visible;
                    }
                }

                // When we open the nav, everything pushes over from the left
                &-push {
                    transition: 0.25s ease all 0.1s;

                    .--small-nav-open & {
                        transform: translateX($small-panel-width);
                    }
                }
            }


            .container {
                align-content: center;
                align-items: center;
                display: flex;
                height: 54px;
                justify-content: space-between;
            }

            a {
                display: block;
                font-size: 15px;
                line-height: 1;
            }

            // These links go back to the previous level of nav
            .subpanel-header {
                padding: 20px 28px 18px 50px;
                position: relative;

                .icon-left {
                    font-size: 14px;
                    left: 20px;
                    pointer-events: none;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            // This blocks the rest of the page when the nav is open
            .small-nav-overlay {
                background: rgba($color--black, 0.7);
                height: 100vh;
                left: 0;
                opacity: 0;
                pointer-events: none;
                position: fixed;
                top: 0;
                width: 100vw;
                z-index: 6;

                .--small-nav-open & {
                    opacity: 1;
                    pointer-events: auto;
                }
            }

            .nav-overlay {
                background: rgba($color--almost-black, 0.7);
                display: none;
                height: 100%;
                position: absolute;
                width: 100%;
                z-index: 6;

                .nav-panel.--active + & {
                    display: block;
                }
            }

            .search-panel ~ .nav-overlay {
                display: block;
            }

            .slide-toggle-enter-active,
            .slide-toggle-leave-active {
                transform: scaleY(1);
                transform-origin: top;
                transition: transform 250ms;
            }

            .slide-toggle-enter,
            .slide-toggle-leave-to {
                transform: scaleY(0);
            }
        }
    }

    // NGA and Monolith overrides
    .global-header {

        .toolbar {

            &-left {
                min-height: 36px;

                a {
                    line-height: 14px;
                }
            }

            &-dropdown {

                &.--active {
                    z-index: 999996;
                }
            }
        }

        .small-nav {

            a {
                border-bottom: 0;
                text-decoration: none;

                &:hover {
                    color: inherit;
                    border-bottom: 0;
                    text-decoration: underline;
                }
            }

            &-subpanel {

                > a {

                    &,
                    &:hover {
                        color: $color--white;
                        background-color: $color--almost-black;
                    }
                }
            }
        }

        .modal-bg {

            &.--visible {
                right: calc(100% - 100vw); // Moves the modal back into the viewport
            }
        }
    }

    .nav-overlay-on-searchOpen{
        display: block !important;
    }
</style>